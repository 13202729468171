.container-btn-delete-user {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(208, 207, 207);
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.btn-delete-user {
  margin: 5px !important;
  width: 150px;
}
