#modal-container,
#modal-container * {
  border-radius: 0px;
}
#modal-container .modal-content {
  padding: 20px;
  border: 0px;
}
#modal-container .modal-text {
  padding: 20px;
  border: 1px solid rgb(208, 208, 208);
  background-color: var(--grey-background);
}
