/*
#co-user
*/
#co-user {
  max-width: 500px;
  margin: 0 auto;
}

#co-user .container-table {
  border: 1px solid gainsboro;
  margin-top: 20px;
  overflow: auto;
  height: 400px;
}
