/*
#modal-add-user
*/

#modal-add-user {
  background-color: var(--grey-background);
}
#modal-add-user .container-modal-content,
#modal-add-user .row,
#modal-add-user .col {
  padding: 0px;
  margin: 0px;
}

#modal-add-user .title-area,
#modal-add-user .title-service,
#modal-add-user .title-add {
  padding-bottom: 10px;
  font-weight: 700;
}

#modal-add-user .input-group {
  padding-bottom: 10px;
}

#modal-add-user .form-control {
  border-radius: 0px;
}

#modal-add-user .input-group-text {
  background-color: #666666;
  border: 0px solid #ced4da;
  /* border-top-width: 1px;
  border-bottom-width: 1px; */
  border-radius: 0px;
  color: white;
}

#modal-add-user .col-table-service-user,
#modal-add-user .col-table-area-user {
  overflow: auto;
  max-height: 200px;
}

#modal-add-user .col-content-user-add {
  background-color: rgb(255, 255, 255);
}
#modal-add-user .content-user-add {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#modal-add-user .btn-add {
  margin: 10px;
}
