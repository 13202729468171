@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
* {
  font-family: "Open Sans", sans-serif;
}

.ui.stackable.menu {
  background-color: #333;
  border-radius: 0;
  margin-bottom: 0px;
}

.ui.menu .item,
.ui.menu .item > a {
  color: white !important;
}

#bo-layout-buttons {
  border-radius: 0 !important;
}

#main > .card,
#article > .card {
  background-color: white;
  border: 1px solid #ddd !important;
  border-radius: 0;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
  height: 100%;
  padding: 15px;
}

.ui.secondary.pointing.menu{
    width: 100%;
}

.ui.secondary.pointing.menu .item{
    color: #000!important;
    font-size: 18px;
    font-weight: 600;
    width: 50%;
}

.ui.bottom.attached.segment.active.tab{
    border: 0
}
#area__details__form__meeting{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

#area__details__form__meeting button:first-child{
    margin-right: 10px;
}

#area__details__form__meeting button:last-child{
    margin-left: 10px;
}

#area__details__form__meeting .field{
    flex-grow: 1;
}

#area__details__form__meeting .field > .ui.input{
    width: 100%;
}

.area__details__table td{
    vertical-align: middle!important;
}

.area__details__table .in_time{
    background-color: #FCFFF5;
    color: #2c662d!important
}

.area__details__table .later_access{
    background-color: #FFF6F6;
    color: #9f3a38!important
}

.area__details__table .early_access{
    background-color: #FFFBF7;
    color: #9e7738!important
}
/*
 * SETTINGS-COLORS
 */

/*
  * Primary colors
  */
:root {
  --primary: #c00000;
  --primary-hover: #c75d5d;
  --grey-background: #efefef;
  --success: #10a908;
  --black: #000000;
}

/****************************
 * RESET
 ****************************/

/**
 * HTML5 Reset
 * We have learned much from/been inspired by/taken code where offered from:
 * Eric Meyer                  :: http://meyerweb.com
 * HTML5 Doctor                :: http://html5doctor.com
 * and the HTML5 Boilerplate   :: http://html5boilerplate.com
 */

html,
body,
#root,
.App {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-light {
  background: rgb(231, 231, 231);
  border-radius: 3px;
}

.mid-width {
  width: 70%;
}

.modal-width {
  width: 400px;
}
.margin-top-big {
  margin-top: 90px;
}
.width-admins {
  max-width: 300px;
}

.mid-width-areas {
  width: 55%;
}
.small-modal {
  width: 250px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.small-button {
  width: 170px;
}
.min-margin-right {
  display: flex;
  justify-content: space-between;
}
.margin-top {
  margin-top: 40px;
}
.margin-top-med {
  margin-top: 50px;
}
.margin-top-first {
  margin-top: 20px;
}

.display-card-buttons {
  display: flex;
  justify-content: space-between;
}

.margin-down {
  margin-bottom: 60px;
}

.margin-top-card-small {
  margin-top: 20px;
}

.margin-top-first-area {
  margin-top: 40px;
  min-width: 150px;
}

.font-black {
  color: black;
}

.margin-left {
  margin-left: 70px;
}

.list-number {
  font-size: 20px;
}
.margin-top-card {
  margin-top: 90px;
}

.max-height {
  height: 100%;
}

.margin-left-small {
  margin-left: 5px;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.grid,
.ui.grid,
.row,
.column {
  margin: 0;
  padding: 0;
}
.modal {
  top: auto;
  left: auto;
  height: auto;
}

:root {
  --pixel: 5px;
  --color01: #030103;
  --color02: #24071d;
  --color03: #450e37;
  --color04: #651450;
  --color05: #861b6a;
  --color06: #a72184;
  --color07: #c7289e;
  --color08: #d83eb1;
  --color09: #df5fbe;
  --color10: #e26fc5;
}
.br01 {
  border: 5px solid #030103;
  border: var(--pixel) solid var(--color01);
}
.br02 {
  border: 5px solid #24071d;
  border: var(--pixel) solid var(--color02);
}
.br03 {
  border: 5px solid #450e37;
  border: var(--pixel) solid var(--color03);
}
.br04 {
  border: 5px solid #651450;
  border: var(--pixel) solid var(--color04);
}
.br05 {
  border: 5px solid #861b6a;
  border: var(--pixel) solid var(--color05);
}
.br06 {
  border: 5px solid #a72184;
  border: var(--pixel) solid var(--color06);
}
.br07 {
  border: 5px solid #c7289e;
  border: var(--pixel) solid var(--color07);
}
.br08 {
  border: 5px solid #d83eb1;
  border: var(--pixel) solid var(--color08);
}
.br09 {
  border: 5px solid #df5fbe;
  border: var(--pixel) solid var(--color09);
}
.br10 {
  border: 5px solid #e26fc5;
  border: var(--pixel) solid var(--color10);
}

.children {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

/*
#container-dashboard-area
*/
#container-dashboard-area,
#container-dashboard-area .row,
#container-dashboard-area .column {
  padding: 0px;
  margin: 0px;
}

#container-dashboard-area .dropdown-area {
  min-width: 250px;
}

#container-dashboard-area .list-area {
  padding: 20px;
  max-width: 800px;
}

/*
#container-services-details
*/

#container-services-details,
#container-services-details .row,
#container-services-details .col {
  padding: 0px;
  margin: 0px;
}

#container-services-details h2.br0 {
  margin-bottom: 1em;
}

.title-user,
.title-meeting {
  align-items: center;
  height: 50px;
}

.title-meeting .ui.input {
  width: 100% !important;
}

.title-meeting .ui.input input {
  border-radius: 0 !important;
}

.title-user .input-group.mb-3 .field {
  width: calc(100% - 63px) !important;
}

.title-user .input-group.mb-3 .ui.input {
  width: 100%;
}

.title-user .input-group.mb-3 input {
  border-radius: 0 !important;
  width: 100%;
}

.title-user .input-group.mb-3 input,
.title-user .input-group.mb-3 button {
  font-family: "Open Sans", sans-serif !important;
}

.title-user .input-group.mb-3 button i {
  margin: 0 !important;
}

/*
#service-meeting
*/
#service-meeting .container-table,
#service-user .container-table {
  /* max-height: 400px; */
  min-width: 0px;
  overflow: auto;
}

/*
#modal-assign-user
*/
#modal-assign-user .container-table {
  height: 300px;
  overflow: auto;
}
#modal-assign-user .container-title {
  padding: 10px 0px 10px 0px;
  box-sizing: content-box;
}
#modal-assign-user .p-00 {
  height: 58px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

/*
#container-service-disponibilitat
*/
#container-service-disponibilitat,
#container-service-disponibilitat .row,
#container-service-disponibilitat .col {
  padding: 0px;
  margin: 0px;
}
#container-service-disponibilitat .container-calendar {
  display: flex;
  justify-content: center;
}

/*
#modal-disponibilitat-delete
*/

#modal-disponibilitat-delete,
#modal-disponibilitat-delete .container,
#modal-disponibilitat-delete .row,
#modal-disponibilitat-delete .col,
#modal-disponibilitat-delete p {
  padding: 0px;
  margin: 0px;
}
#modal-disponibilitat-delete .row-select-delete {
  border: 1px solid #cecece;
}
#modal-disponibilitat-delete .table {
  border: 0px;
}
#modal-disponibilitat-delete .col-select-delete {
  height: 300px;
  overflow: auto;
}

#modal-disponibilitat-delete .select-delete {
  background-color: black;
  color: aliceblue;
  padding: 20px 20px 20px 20px;
  margin: 10px 10px 10px 10px;
}

#modal-disponibilitat-delete .select-delete:hover {
  background-color: #db2828;
  color: aliceblue;
}

#modal-disponibilitat-delete .segment {
  border: none;
  box-shadow: none;
}

#modal-delete-confirm .btn-confirm {
  background-color: var(--primary);
}
#modal-delete-confirm .btn-confirm:hover {
  background-color: var(--primary-hover);
}

/*
#modal-add-user
*/

#modal-add-user {
  background-color: var(--grey-background);
}
#modal-add-user .container-modal-content,
#modal-add-user .row,
#modal-add-user .col {
  padding: 0px;
  margin: 0px;
}

#modal-add-user .title-area,
#modal-add-user .title-service,
#modal-add-user .title-add {
  padding-bottom: 10px;
  font-weight: 700;
}

#modal-add-user .input-group {
  padding-bottom: 10px;
}

#modal-add-user .form-control {
  border-radius: 0px;
}

#modal-add-user .input-group-text {
  background-color: #666666;
  border: 0px solid #ced4da;
  /* border-top-width: 1px;
  border-bottom-width: 1px; */
  border-radius: 0px;
  color: white;
}

#modal-add-user .col-table-service-user,
#modal-add-user .col-table-area-user {
  overflow: auto;
  max-height: 200px;
}

#modal-add-user .col-content-user-add {
  background-color: rgb(255, 255, 255);
}
#modal-add-user .content-user-add {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#modal-add-user .btn-add {
  margin: 10px;
}

.t {
  position: relative;
}

.tt {
  /* background-color: #000000; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.none {
  display: none;
}

.sise {
  display: block;
}

#container-fullcalendar {
  padding: 30px;
  flex-grow: 1;
  max-width: 800px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

#container-fullcalendar .fc-toolbar-chunk > button,
#container-fullcalendar .fc-button-group > button {
  background-color: #c00000;
  border-color: #c00000;
}

#container-fullcalendar .fc-toolbar-title {
  color: black;
  text-transform: capitalize;
}

#container-fullcalendar .fc-col-header-cell-cushion {
  color: #c00000;
  text-transform: uppercase;
  font-size: 20px;
  padding: 20px;
}

#container-fullcalendar a {
  color: black;
}

#container-fullcalendar
  .fc-timegrid-slot-label-cushion
  .fc-scrollgrid-shrink-cushion {
  color: black;
}

#container-fullcalendar .fc .fc-timegrid-slot {
  height: 100px;
}

#container-fullcalendar .fc .fc-daygrid-day.fc-day-today,
#container-fullcalendar .fc .fc-timegrid-col.fc-day-today {
  background-color: #c00000;
}

#container-fullcalendar .fc .fc-timegrid-axis-cushion {
  color: #c00000;
}

#container-fullcalendar .fc .fc-timegrid-slot-label-cushion {
  color: black;
}

#container-fullcalendar .fc .fc-more-popover .fc-popover-body {
  background-color: white;
}

#container-fullcalendar .fc-popover-header {
  background-color: #c00000 !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
}

/* 
#container-fullcalendar  
*/

.blur-loader {
  -webkit-filter: blur(9px);
          filter: blur(9px);
}

#modal-disponibilitat label {
  display: block;
}

#modal-disponibilitat input[type="number"] {
  width: 70px;
}

#modal-disponibilitat .button-group {
  display: flex;
  justify-content: flex-end;
}

/*
#bo-form-calendar
*/

#bo-form-calendar,
#bo-form-calendar .grid,
#bo-form-calendar .row,
#bo-form-calendar .column,
#bo-form-calendar .ui.button {
  padding: 0px;
  margin: 0px;
}

#bo-form-calendar .column {
  padding: 10px;
}

#bo-form-calendar .ui.button {
  width: 100px;
  padding: 10px 10px 10px 10px;
}

/*
#list-event-info
*/

#list-event-info .list-item,
#list-event-info p {
  padding: 0;
  margin: 0;
}
#list-event-info {
  background-color: rgb(223, 223, 223);
}
#list-event-info .list-item {
  display: flex;
  padding: 5px;
}
#list-event-info .p-01 {
  width: 50%;
  font-weight: 700;
}
#list-event-info .p-02 {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*
#co-user
*/
#co-user {
  max-width: 500px;
  margin: 0 auto;
}

#co-user .container-table {
  border: 1px solid gainsboro;
  margin-top: 20px;
  overflow: auto;
  height: 400px;
}

#error-login {
  background-color: #dcdcdc !important;
}
#error-login .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#error-login h2 {
  font-size: 70px;
  color: tomato;
}
#error-login p {
  font-size: 20px;
  max-width: 500px;
  text-align: left;
  margin: 50px !important;
  padding: 50px;
  color: #000;
}
#error-login .btn-error {
  background-color: transparent;
  border: 1px solid tomato;
  color: tomato;
}
#error-login .btn-error:hover {
  background-color: tomato;
  border: 1px solid tomato;
  color: #dcdcdc;
}

#container-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 8px 0px #c7c7c7;
}
.green {
  color: green;
}
.red {
  color: red;
}

#modal-container,
#modal-container * {
  border-radius: 0px;
}
#modal-container .modal-content {
  padding: 20px;
  border: 0px;
}
#modal-container .modal-text {
  padding: 20px;
  border: 1px solid rgb(208, 208, 208);
  background-color: var(--grey-background);
}

.container-btn-delete-user {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(208, 207, 207);
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.btn-delete-user {
  margin: 5px !important;
  width: 150px;
}

#modal-add-admin-area .container-search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
#modal-add-admin-area .container-search .text-selected {
  overflow: auto;
  border: 1px solid #858585;
  color: #ffffff;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-add-admin-area .container-search .rojo {
  background-color: var(--black);
}
#modal-add-admin-area .container-search .verde {
  background-color: var(--success);
}

#modal-add-admin-area .container-mail {
  display: flex;
  justify-content: center;
}

#modal-add-admin-area .container-mail .box-mail {
  overflow: auto;
  padding: 20px;
  width: 400px;
  height: 200px;
  border: 1px solid rgb(182, 182, 182);
  background-color: var(--grey-background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal-add-admin-area .container-mail .box-mail p {
  background-color: rgb(61, 61, 61);
  color: white;
  width: 300px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

/*
    CSS Architecture framework: ITCSS

    Inverted Triangle Cascading Style Sheets
        1. Settings:    variables, configs
        2. Tools:       functions. clearfix, hidden, font-size
        3. Generic:     3rd party, normalize.css, reset, shared, box-sizing
        4. Base:        bare elements, Unclassed HTML elements (type selectors), Page, Headings, Images, Tables
        5. Objects:     layout classes, wrapper, layout, media
        6. Components:  UI Classes
        7. Trumps:      Overrides, IDs and !important (Rules with high specificity)

    https://github.com/inuitcss/inuitcss
 */

/*
 * 1. SETTINGS
 */

/*
 * 2. TOOLS
 */

/*
 * 3. GENERIC
 */

/*
 * 4. BASE
 */

/*
 * 5. OBJECTS
 */

/*
 * 6. COMPONENTS
 */ /*
 * 7. TRUMPS
 */

.fc-more-popover {
  max-height: 95%;
  overflow-y: auto;
}
.checkbox::after{
  display: none;
}
body {
  background-color: #f5f5f5;
}

a {
  color: #c00000;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400;
}

.children {
  background-color: white;
  border-radius: 0;
  border: 1px solid #ddd;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
}

.ui.blue.button,
.ui.blue.buttons .button,
.ui.positive.button,
.ui.positive.buttons .button,
.ui.negative.button,
.ui.negative.buttons .button {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
}

.ui.negative.button,
.ui.negative.buttons .button {
  background-color: #666666 !important;
}

.ui.positive.button,
.ui.positive.buttons .button {
  background-color: #c00000 !important;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #c00000 !important;
}

.ui.fluid.dropdown,
.ui.selection.dropdown {
  border-radius: 0 !important;
}

.ui.form {
  padding: 25px;
  border: 1px solid #ddd;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
}

.ui.form.clean {
  padding: 0;
  border: 0;
  box-shadow: none;
}

.ui.modal > .close {
  color: #222;
  top: 0;
  right: 0;
}
.modal {
  overflow: visible;
  overflow: initial;
}

#MeetingCard {
  width: 100%;
  padding: 0;
  margin: 0;
}

#MeetingCard > div {
  width: 100% !important;
  border-radius: 0 !important;
  border: 1px solid #ddd;
  box-shadow: none !important;
}

#container-dashboard-area .ui.selection.dropdown {
  width: 100% !important;
}

#container-dashboard-area .ui.divided.list.list-area.flex-grow-1 {
  background: #f5f5f5;
  border: 1px solid #ddd;
  width: 100% !important;
  max-width: 100% !important;
}

#container-dashboard-area .ui.divided.list.list-area.flex-grow-1 > .item {
  margin: auto 1em;
  list-style-type: disc;
  color: #c00000;
}

#container-dashboard-area
  .ui.divided.list.list-area.flex-grow-1
  > .item
  .content:hover,
#container-dashboard-area
  .ui.divided.list.list-area.flex-grow-1
  > .item
  .right.floated.content:hover {
  color: #5bc8d5;
}

.ui.table th {
  background-color: #333 !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: white !important;
}

#AdminArea {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
}

.tox-notifications-container {
  display: none !important;
}
#AdminArea table {
  background: none;
  border: 0;
  padding: 0;
}

#AdminAreaDetails .ui.input {
  width: calc(100% - 62.1px);
}

#AdminAreaDetails .ui.input input {
  border-radius: 0 !important;
}

#header {
  background-color: #efefef;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  content: var(--bs-breadcrumb-divider, ">");
}

.breadcrumb li {
  font-size: 12px;
}

.breadcrumb a {
  color: #333;
  text-decoration: none;
}

tr.table-meetings{
  background-color: #fffbf7!important;
  color: #9e7738!important
}

tr.table-meetings.positive{
  background-color: green!important;
}

tr.table-meetings.negative{
  background-color: red!important;
}

.datepicker_style{
  width: 100% !important;
}
