/*
 * SETTINGS-COLORS
 */

/*
  * Primary colors
  */
:root {
  --primary: #c00000;
  --primary-hover: #c75d5d;
  --grey-background: #efefef;
  --success: #10a908;
  --black: #000000;
}
