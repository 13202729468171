/*
#bo-form-calendar
*/

#bo-form-calendar,
#bo-form-calendar .grid,
#bo-form-calendar .row,
#bo-form-calendar .column,
#bo-form-calendar .ui.button {
  padding: 0px;
  margin: 0px;
}

#bo-form-calendar .column {
  padding: 10px;
}

#bo-form-calendar .ui.button {
  width: 100px;
  padding: 10px 10px 10px 10px;
}
