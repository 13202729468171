/*
#container-dashboard-area
*/
#container-dashboard-area,
#container-dashboard-area .row,
#container-dashboard-area .column {
  padding: 0px;
  margin: 0px;
}

#container-dashboard-area .dropdown-area {
  min-width: 250px;
}

#container-dashboard-area .list-area {
  padding: 20px;
  max-width: 800px;
}
