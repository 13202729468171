#error-login {
  background-color: #dcdcdc !important;
}
#error-login .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#error-login h2 {
  font-size: 70px;
  color: tomato;
}
#error-login p {
  font-size: 20px;
  max-width: 500px;
  text-align: left;
  margin: 50px !important;
  padding: 50px;
  color: #000;
}
#error-login .btn-error {
  background-color: transparent;
  border: 1px solid tomato;
  color: tomato;
}
#error-login .btn-error:hover {
  background-color: tomato;
  border: 1px solid tomato;
  color: #dcdcdc;
}
