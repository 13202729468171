#modal-disponibilitat label {
  display: block;
}

#modal-disponibilitat input[type="number"] {
  width: 70px;
}

#modal-disponibilitat .button-group {
  display: flex;
  justify-content: flex-end;
}
