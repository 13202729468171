#area__details__form__meeting{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

#area__details__form__meeting button:first-child{
    margin-right: 10px;
}

#area__details__form__meeting button:last-child{
    margin-left: 10px;
}

#area__details__form__meeting .field{
    flex-grow: 1;
}

#area__details__form__meeting .field > .ui.input{
    width: 100%;
}

.area__details__table td{
    vertical-align: middle!important;
}

.area__details__table .in_time{
    background-color: #FCFFF5;
    color: #2c662d!important
}

.area__details__table .later_access{
    background-color: #FFF6F6;
    color: #9f3a38!important
}

.area__details__table .early_access{
    background-color: #FFFBF7;
    color: #9e7738!important
}