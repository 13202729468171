/*
#list-event-info
*/

#list-event-info .list-item,
#list-event-info p {
  padding: 0;
  margin: 0;
}
#list-event-info {
  background-color: rgb(223, 223, 223);
}
#list-event-info .list-item {
  display: flex;
  padding: 5px;
}
#list-event-info .p-01 {
  width: 50%;
  font-weight: 700;
}
#list-event-info .p-02 {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
