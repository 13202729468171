/*
#container-services-details
*/

#container-services-details,
#container-services-details .row,
#container-services-details .col {
  padding: 0px;
  margin: 0px;
}

#container-services-details h2.br0 {
  margin-bottom: 1em;
}

.title-user,
.title-meeting {
  align-items: center;
  height: 50px;
}

.title-meeting .ui.input {
  width: 100% !important;
}

.title-meeting .ui.input input {
  border-radius: 0 !important;
}

.title-user .input-group.mb-3 .field {
  width: calc(100% - 63px) !important;
}

.title-user .input-group.mb-3 .ui.input {
  width: 100%;
}

.title-user .input-group.mb-3 input {
  border-radius: 0 !important;
  width: 100%;
}

.title-user .input-group.mb-3 input,
.title-user .input-group.mb-3 button {
  font-family: "Open Sans", sans-serif !important;
}

.title-user .input-group.mb-3 button i {
  margin: 0 !important;
}

/*
#service-meeting
*/
#service-meeting .container-table,
#service-user .container-table {
  /* max-height: 400px; */
  min-width: 0px;
  overflow: auto;
}

/*
#modal-assign-user
*/
#modal-assign-user .container-table {
  height: 300px;
  overflow: auto;
}
#modal-assign-user .container-title {
  padding: 10px 0px 10px 0px;
  box-sizing: content-box;
}
#modal-assign-user .p-00 {
  height: 58px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
