:root {
  --pixel: 5px;
  --color01: #030103;
  --color02: #24071d;
  --color03: #450e37;
  --color04: #651450;
  --color05: #861b6a;
  --color06: #a72184;
  --color07: #c7289e;
  --color08: #d83eb1;
  --color09: #df5fbe;
  --color10: #e26fc5;
}
.br01 {
  border: var(--pixel) solid var(--color01);
}
.br02 {
  border: var(--pixel) solid var(--color02);
}
.br03 {
  border: var(--pixel) solid var(--color03);
}
.br04 {
  border: var(--pixel) solid var(--color04);
}
.br05 {
  border: var(--pixel) solid var(--color05);
}
.br06 {
  border: var(--pixel) solid var(--color06);
}
.br07 {
  border: var(--pixel) solid var(--color07);
}
.br08 {
  border: var(--pixel) solid var(--color08);
}
.br09 {
  border: var(--pixel) solid var(--color09);
}
.br10 {
  border: var(--pixel) solid var(--color10);
}
