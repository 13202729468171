.t {
  position: relative;
}

.tt {
  /* background-color: #000000; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.none {
  display: none;
}

.sise {
  display: block;
}

#container-fullcalendar {
  padding: 30px;
  flex-grow: 1;
  max-width: 800px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

#container-fullcalendar .fc-toolbar-chunk > button,
#container-fullcalendar .fc-button-group > button {
  background-color: #c00000;
  border-color: #c00000;
}

#container-fullcalendar .fc-toolbar-title {
  color: black;
  text-transform: capitalize;
}

#container-fullcalendar .fc-col-header-cell-cushion {
  color: #c00000;
  text-transform: uppercase;
  font-size: 20px;
  padding: 20px;
}

#container-fullcalendar a {
  color: black;
}

#container-fullcalendar
  .fc-timegrid-slot-label-cushion
  .fc-scrollgrid-shrink-cushion {
  color: black;
}

#container-fullcalendar .fc .fc-timegrid-slot {
  height: 100px;
}

#container-fullcalendar .fc .fc-daygrid-day.fc-day-today,
#container-fullcalendar .fc .fc-timegrid-col.fc-day-today {
  background-color: #c00000;
}

#container-fullcalendar .fc .fc-timegrid-axis-cushion {
  color: #c00000;
}

#container-fullcalendar .fc .fc-timegrid-slot-label-cushion {
  color: black;
}

#container-fullcalendar .fc .fc-more-popover .fc-popover-body {
  background-color: white;
}

#container-fullcalendar .fc-popover-header {
  background-color: #c00000 !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
}

/* 
#container-fullcalendar  
*/

.blur-loader {
  filter: blur(9px);
}
