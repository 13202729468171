.ui.secondary.pointing.menu{
    width: 100%;
}

.ui.secondary.pointing.menu .item{
    color: #000!important;
    font-size: 18px;
    font-weight: 600;
    width: 50%;
}

.ui.bottom.attached.segment.active.tab{
    border: 0
}