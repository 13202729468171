#container-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 8px 0px #c7c7c7;
}
.green {
  color: green;
}
.red {
  color: red;
}
