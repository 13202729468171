/*
#container-service-disponibilitat
*/
#container-service-disponibilitat,
#container-service-disponibilitat .row,
#container-service-disponibilitat .col {
  padding: 0px;
  margin: 0px;
}
#container-service-disponibilitat .container-calendar {
  display: flex;
  justify-content: center;
}

/*
#modal-disponibilitat-delete
*/

#modal-disponibilitat-delete,
#modal-disponibilitat-delete .container,
#modal-disponibilitat-delete .row,
#modal-disponibilitat-delete .col,
#modal-disponibilitat-delete p {
  padding: 0px;
  margin: 0px;
}
#modal-disponibilitat-delete .row-select-delete {
  border: 1px solid #cecece;
}
#modal-disponibilitat-delete .table {
  border: 0px;
}
#modal-disponibilitat-delete .col-select-delete {
  height: 300px;
  overflow: auto;
}

#modal-disponibilitat-delete .select-delete {
  background-color: black;
  color: aliceblue;
  padding: 20px 20px 20px 20px;
  margin: 10px 10px 10px 10px;
}

#modal-disponibilitat-delete .select-delete:hover {
  background-color: #db2828;
  color: aliceblue;
}

#modal-disponibilitat-delete .segment {
  border: none;
  box-shadow: none;
}

#modal-delete-confirm .btn-confirm {
  background-color: var(--primary);
}
#modal-delete-confirm .btn-confirm:hover {
  background-color: var(--primary-hover);
}
