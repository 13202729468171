#modal-add-admin-area .container-search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
#modal-add-admin-area .container-search .text-selected {
  overflow: auto;
  border: 1px solid #858585;
  color: #ffffff;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-add-admin-area .container-search .rojo {
  background-color: var(--black);
}
#modal-add-admin-area .container-search .verde {
  background-color: var(--success);
}

#modal-add-admin-area .container-mail {
  display: flex;
  justify-content: center;
}

#modal-add-admin-area .container-mail .box-mail {
  overflow: auto;
  padding: 20px;
  width: 400px;
  height: 200px;
  border: 1px solid rgb(182, 182, 182);
  background-color: var(--grey-background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal-add-admin-area .container-mail .box-mail p {
  background-color: rgb(61, 61, 61);
  color: white;
  width: 300px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
