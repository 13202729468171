* {
  font-family: "Open Sans", sans-serif;
}

.ui.stackable.menu {
  background-color: #333;
  border-radius: 0;
  margin-bottom: 0px;
}

.ui.menu .item,
.ui.menu .item > a {
  color: white !important;
}

#bo-layout-buttons {
  border-radius: 0 !important;
}

#main > .card,
#article > .card {
  background-color: white;
  border: 1px solid #ddd !important;
  border-radius: 0;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
  height: 100%;
  padding: 15px;
}
