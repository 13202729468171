/****************************
 * RESET
 ****************************/

/**
 * HTML5 Reset
 * We have learned much from/been inspired by/taken code where offered from:
 * Eric Meyer                  :: http://meyerweb.com
 * HTML5 Doctor                :: http://html5doctor.com
 * and the HTML5 Boilerplate   :: http://html5boilerplate.com
 */

html,
body,
#root,
.App {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-light {
  background: rgb(231, 231, 231);
  border-radius: 3px;
}

.mid-width {
  width: 70%;
}

.modal-width {
  width: 400px;
}
.margin-top-big {
  margin-top: 90px;
}
.width-admins {
  max-width: 300px;
}

.mid-width-areas {
  width: 55%;
}
.small-modal {
  width: 250px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.small-button {
  width: 170px;
}
.min-margin-right {
  display: flex;
  justify-content: space-between;
}
.margin-top {
  margin-top: 40px;
}
.margin-top-med {
  margin-top: 50px;
}
.margin-top-first {
  margin-top: 20px;
}

.display-card-buttons {
  display: flex;
  justify-content: space-between;
}

.margin-down {
  margin-bottom: 60px;
}

.margin-top-card-small {
  margin-top: 20px;
}

.margin-top-first-area {
  margin-top: 40px;
  min-width: 150px;
}

.font-black {
  color: black;
}

.margin-left {
  margin-left: 70px;
}

.list-number {
  font-size: 20px;
}
.margin-top-card {
  margin-top: 90px;
}

.max-height {
  height: 100%;
}

.margin-left-small {
  margin-left: 5px;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.grid,
.ui.grid,
.row,
.column {
  margin: 0;
  padding: 0;
}
.modal {
  top: auto;
  left: auto;
  height: auto;
}
